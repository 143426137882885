<template>
  <div>
    <div class="ps-block--vendor-dashboard">
      <div
          class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
      >
        <h3>Runners Requests</h3>
        <div class="justify-content-between">
          &nbsp;&nbsp;
          <router-link
              :to="{ name: 'admin_runners' }"
              v-if="auth.isAuthenticated"
              class="ps-btn btn-sm"
          ><i class="icon-list"></i> Runners</router-link
          >
        </div>
      </div>
      <div class="ps-block__content">

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered">
            <thead>
            <tr>
              <th>Date</th>
              <th>Runner Name</th>
              <th>Category</th>
              <th>Details</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="runnersRequest in runnersRequests" :key="runner.id">
              <td>{{ runnersRequest.created_at|formatDate }}</td>
              <td><span class="text-muted">{{runnersRequest.user_id }}</span></td>
              <td>{{ runnersRequest.runner_category_id | toCurrency }}</td>
              <td>
                <router-link :to="{name: 'admin_runner_detail', params: {id: runnersRequest.id}}"><i class="fa fa-eye"></i></router-link>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr v-if="(!meta || meta.total === 0) && !isLoading">
              <td colspan="6" class="text-center m-4 text-danger p-4">No runners requests available</td>
            </tr>
            <tr v-else-if="isLoading">
              <td colspan="6" class="text-center m-4 text-danger">Loading runners requests...</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "ListRunnersRequests",
  data() {
    return {
      runnersRequests:[],
      meta: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    this.getRunners();
  },
  methods: {
    getRunners() {
      this.isLoading = true
      axios.get('runners/requests').then((response) => {
        this.isLoading = false
        this.runnersRequests = response.data.data
        this.meta = response.data.meta
      }).catch((error) => {
        this.isLoading = false
      });
    },
  },
}
</script>

<style scoped>

</style>